import React, { useMemo, useState } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { ReadOnlyTypography } from '@warehouse/shared/ui';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

interface TitleYearInputFieldProps {
	label: string;
	path: string;
	style?: React.CSSProperties;
	forcedEditMode?: boolean;
}

function getVariant(value: OptionalInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}

export function TitleYearInputField({ label, path, style, forcedEditMode }: TitleYearInputFieldProps) {
	const { readOnlyValue, setValue, value, commit, error } = useTitleAutoSave<OptionalInherited<string>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	const [errorField, setErrorField] = useState<string | undefined>(undefined);

	return (
		<TitleEditableField
			wrapperStyle={style}
			label={label}
			path={path}
			direction="column"
			forcedEditMode={forcedEditMode}
			readComponent={
				<ReadOnlyTypography variant={getVariant(value)}>{readOnlyValue?.displayValue || ''}</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					type="number"
					aria-label={label}
					height={38}
					value={value?.displayValue || ''}
					onBlur={() => commit()}
					onChange={(e) => {
						setErrorField(undefined);
						const validRegexValue = /^(18|19|20|21)\d\d$/;
						const yearValue = e.target.value;
						setValue((prev) => ({
							...prev,
							displayValue: yearValue,
						}));
						if (!validRegexValue.test(yearValue)) setErrorField('Should be between 1800 and 2199.');
					}}
					variant={getVariant(value)}
					error={error || errorField}
					disabledMargin
				/>
			}
		/>
	);
}
